import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout';

const NotFoundPage = ({ pageContext, location }) => {
  const { t } = useTranslation();
  return (
    <Layout lang={pageContext.lang} indexed={false} location={location}>
      <div>
        <h1>{t('404.not_found')}</h1>
        <p>{t('404.not_found_description')}</p>
      </div>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default NotFoundPage;
